<template>
<div class="col-md-4 login-sec">
  <h2>{{ $t('login') }}</h2>
  <form @submit.prevent="login" @keydown="form.onKeydown($event)" class="login-form">
    <div class="form-group">
      <label for="exampleInputEmail1" class="text-uppercase">{{ $t('email') }}</label>
      <input type="text" name="email" class="form-control" v-model="form.email" :class="{ 'is-invalid': form.errors.has('email') }">
      <has-error :form="form" field="email" />
    </div>

    <div class="form-group">
      <label for="exampleInputPassword1" class="text-uppercase">{{ $t('password') }}</label>
      <input type="password" name="password" class="form-control" v-model="form.password" :class="{ 'is-invalid': form.errors.has('password') }">
      <has-error :form="form" field="password" />
    </div>

    <div class="form-row">
      <div class="col">
        <div class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" id="remember_me" v-model="remember">
        <label class="custom-control-label small" for="remember_me">{{ $t('remember_me') }}</label>
      </div>
      </div>
      <div class="col text-right">
        <button type="submit" class="btn btn-primary">{{ $t('login') }}</button>
      </div>
    </div>
    <router-link :to="{ name: 'password.request' }" class="small">
      {{ $t('forgot_password') }}
    </router-link>
    <br>
    <router-link :to="{ name: 'register' }" class="small">
      {{ $t('register') }}
    </router-link>
  </form>
</div>
</template>
<script>
import Form from 'vform'
import axios from 'axios'
export default {
  middleware: 'guest',
  layout: 'starter',
  metaInfo () {
    return { title: this.$t('home') }
  },
  data: () => ({
    form: new Form({
      email: '',
      password: ''
    }),
    remember: false,
    title: window.config.appName
  }),
  methods: {
    async login () {
      this.$root.$refs.loading.start()
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      const { data } = await axios.post(apiUrl + 'login', { email: this.form.email, password: this.form.password, remember: this.remember })
      this.$store.dispatch('auth/saveToken', {
        token: data.token,
        remember: this.remember
      })

      await this.$store.dispatch('auth/fetchUser')
      this.$root.$refs.loading.finish()
      this.$router.push({ path: '/dashboard' })
    }
  }
}
</script>
